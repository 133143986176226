/* Box sizing rules */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    -webkit-transition-duration: 0.01ms !important;
            transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;
}

.header {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 64px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 10;
}

@media (max-width: 1025px) {
  .header {
    padding: 0 20px;
    height: 80px;
  }
}

.header .logo {
  height: 6.4rem;
}

.header .navbar .nav-list {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3.2rem;
  list-style: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 785px) {
  .header .navbar .nav-list {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.header .navbar .nav-list .nav-link:link,
.header .navbar .nav-list .nav-link:visited {
  position: relative;
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}

.header .navbar .nav-list .nav-link:hover:after,
.header .navbar .nav-list .nav-link:active:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.header .navbar .nav-list li:last-child .nav-link {
  font-weight: 500;
}

.header .navbar .nav-list .nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: white;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.header .navbar .nav-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  display: none;
}

@media (max-width: 785px) {
  .header .navbar .nav-button {
    display: block;
  }
}

.header .navbar .nav-button svg {
  height: 48px;
  width: 48px;
  pointer-events: none;
}

.header .navbar.nav-open .nav-list {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.95)), to(rgba(0, 0, 0, 0.95)));
  background-image: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95));
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

html {
  overflow-y: scroll;
  -ms-scroll-snap-type: y proximity;
      scroll-snap-type: y proximity;
  scroll-behavior: smooth;
}

section {
  scroll-snap-align: start;
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow-x: hidden;
}

section .section-subheader {
  white-space: nowrap;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 24px;
  color: #4f6591;
}

section .section-header {
  font-weight: 500;
  font-size: 6.4rem;
  line-height: 7.8rem;
}

section p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
}

@media (max-width: 1025px) {
  section .section-subheader {
    font-size: 1.6rem;
  }
  section .section-header {
    font-size: 4.8rem;
  }
  section p {
    font-size: 1.4rem;
  }
}

@media (max-width: 785px) {
  section {
    scroll-snap-align: none;
  }
  section .section-header {
    margin-top: 24px;
  }
}

@media (max-width: 600px) {
  section .section-header {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

section:not(.hero-section) {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

section.hero-section {
  background-image: -webkit-gradient(linear, left top, right top, from(black), to(rgba(0, 0, 0, 0.301))), url(./img/hero.jpg);
  background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0.301)), url(./img/hero.jpg);
  background-size: cover;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
}

section.hero-section div {
  margin-left: 10%;
  color: white;
}

section.hero-section div span {
  font-size: 6.4rem;
  font-weight: 600;
  line-height: 7.8rem;
  display: block;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  -webkit-text-fill-color: black;
}

@media (max-width: 600px) {
  section.hero-section div span {
    font-size: 4.8rem;
    line-height: 6.4rem;
  }
}

@media (max-width: 340px) {
  section.hero-section div span {
    font-size: 4.2rem;
    line-height: 4.8rem;
  }
}

section.hero-section div h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 9.6rem;
  line-height: 11.7rem;
}

@media (max-width: 600px) {
  section.hero-section div h1 {
    font-size: 6.4rem;
    line-height: 9.6rem;
  }
}

@media (max-width: 340px) {
  section.hero-section div h1 {
    font-size: 6rem;
    line-height: 6.4rem;
  }
}

@media (max-width: 1000px) {
  section.hero-section {
    background-image: -webkit-gradient(linear, left top, right top, from(black), to(rgba(0, 0, 0, 0.301))), url(./img/hero_mobile.jpg);
    background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0.301)), url(./img/hero_mobile.jpg);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 90vh, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 90vh, 0 100%);
    background-position-x: right;
  }
}

section.about-section .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

section.about-section .container .section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 64px;
}

section.about-section .container .section-header:before,
section.about-section .container .section-header:after {
  content: "";
  border-top: 1px solid;
  margin: 0 50px 0 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 20px;
          flex: 1 0 20px;
  color: black;
}

@media (max-width: 340px) {
  section.about-section .container .section-header:before,
  section.about-section .container .section-header:after {
    margin: 0 5px 0 0;
  }
}

section.about-section .container .section-header:after {
  margin: 0 0 0 50px;
}

@media (max-width: 340px) {
  section.about-section .container .section-header:after {
    margin: 0 0 0 5px;
  }
}

section.about-section .container .section-subheader,
section.about-section .container p {
  text-align: center;
}

section.about-section .container p {
  width: 550px;
}

@media (max-width: 600px) {
  section.about-section .container p {
    width: 85%;
  }
}

section.services-section .container {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 6.4rem;
}

section.services-section .container .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  justify-self: end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 500px;
}

section.services-section .container .text-container .section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 6.4rem;
}

section.services-section .container .text-container .section-header:before {
  content: "";
  border-top: 1px solid;
  position: absolute;
  left: 0;
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
  width: 300%;
  color: black;
}

@media (max-width: 785px) {
  section.services-section .container .text-container .section-header:before {
    -webkit-transform: translateX(-96%);
            transform: translateX(-96%);
  }
}

section.services-section .container .image-container img {
  width: 600px;
  -webkit-clip-path: polygon(0% 0%, 74.4% 0%, 74.4% 14.5%, 100% 14.5%, 100% 54%, 84.1% 54%, 84.1% 100%, 5.65% 100%, 5.65% 56.3%, 0% 56.3%);
          clip-path: polygon(0% 0%, 74.4% 0%, 74.4% 14.5%, 100% 14.5%, 100% 54%, 84.1% 54%, 84.1% 100%, 5.65% 100%, 5.65% 56.3%, 0% 56.3%);
}

section.services-section:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("img/part_wireframe.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: 20%;
  opacity: 0.1;
}

@media (max-width: 1550px) {
  section.services-section .container {
    gap: 1.6rem;
  }
  section.services-section .container .text-container {
    width: 550px;
  }
}

@media (max-width: 1025px) {
  section.services-section .container .text-container {
    width: 90%;
  }
  section.services-section .container .image-container {
    width: 90%;
  }
}

@media (max-width: 785px) {
  section.services-section .container {
    margin-top: 72px;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    gap: 32px;
  }
  section.services-section .container .text-container {
    width: 70%;
    -ms-grid-column-align: center;
        justify-self: center;
  }
  section.services-section .container .image-container {
    width: 60%;
    -ms-grid-column-align: center;
        justify-self: center;
  }
}

section.machines-section .container {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 6.4rem;
  text-align: right;
}

section.machines-section .container .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 500px;
}

section.machines-section .container .text-container .section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 6.4rem;
}

section.machines-section .container .text-container .section-header:before {
  content: "";
  border-top: 1px solid;
  position: absolute;
  right: 0;
  -webkit-transform: translateX(99%);
          transform: translateX(99%);
  width: 300%;
  color: black;
}

section.machines-section .container .image-container {
  -ms-grid-column-align: end;
      justify-self: end;
}

section.machines-section .container .image-container img {
  width: 600px;
}

section.machines-section:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("img/collet.jpg");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
  background-size: 30%;
  opacity: 0.1;
}

@media (max-width: 1550px) {
  section.machines-section .container {
    gap: 1.6rem;
  }
}

@media (max-width: 1025px) {
  section.machines-section .container .text-container {
    width: 90%;
  }
  section.machines-section .container .image-container {
    width: 90%;
  }
}

@media (max-width: 785px) {
  section.machines-section .container {
    margin-top: 72px;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    gap: 32px;
  }
  section.machines-section .container .text-container {
    -ms-grid-row: 1;
    grid-row: 1;
    width: 70%;
    -ms-grid-column-align: center;
        justify-self: center;
    text-align: left;
  }
  section.machines-section .container .text-container .section-header {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  section.machines-section .container .text-container .section-header::before {
    -webkit-transform: translate(-30%);
            transform: translate(-30%);
  }
  section.machines-section .container .image-container {
    -ms-grid-row: 2;
    grid-row: 2;
    width: 60%;
    -ms-grid-column-align: center;
        justify-self: center;
  }
}

section.gallery-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f2f2f2;
}

section.gallery-section .swiper {
  width: 900px;
  height: 500px;
}

@media (max-width: 1025px) {
  section.gallery-section .swiper {
    width: 90%;
  }
}

section.gallery-section .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

section.gallery-section .container .section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 64px;
}

@media (max-width: 1025px) {
  section.gallery-section .container .section-header {
    margin-bottom: 16px;
  }
}

@media (max-width: 785px) {
  section.gallery-section .container .section-header {
    margin-bottom: 64px;
  }
}

section.gallery-section .container .section-header:before,
section.gallery-section .container .section-header:after {
  content: "";
  border-top: 1px solid;
  margin: 0 50px 0 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 20px;
          flex: 1 0 20px;
  color: black;
}

section.gallery-section .container .section-header:after {
  margin: 0 0 0 50px;
}

section.contact-section {
  position: relative;
  overflow: hidden;
}

section.contact-section .container {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  gap: 6.4rem;
}

@media (max-width: 1025px) {
  section.contact-section .container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    justify-items: center;
  }
  section.contact-section .container .text-container .section-header {
    margin-top: 96px;
  }
  section.contact-section .container .form-container {
    margin-bottom: 64px;
  }
}

section.contact-section .container .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 600px;
}

section.contact-section .container .text-container .section-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  margin-bottom: 48px;
}

@media (max-width: 1025px) {
  section.contact-section .container .text-container .section-header {
    margin-bottom: 16px;
  }
}

section.contact-section .container .text-container .section-header:before {
  content: "";
  border-top: 1px solid;
  position: absolute;
  left: 0;
  -webkit-transform: translateX(-101%);
          transform: translateX(-101%);
  width: 300%;
  color: black;
}

@media (max-width: 785px) {
  section.contact-section .container .text-container .section-header:before {
    -webkit-transform: translateX(-98%);
            transform: translateX(-98%);
  }
}

section.contact-section .container .text-container .section-header-secondary {
  margin-top: 6.4rem;
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 4.8rem;
  line-height: 6rem;
}

@media (max-width: 1025px) {
  section.contact-section .container .text-container .section-header-secondary {
    margin-bottom: 16px;
  }
}

section.contact-section .container .image-container img {
  width: 600px;
}

section.contact-section .form-container {
  -webkit-box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #ffffff;
}

section.contact-section .form-container form {
  height: 70rem;
  padding: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 1025px) {
  section.contact-section .form-container form {
    padding: 48px;
  }
}

@media (max-width: 600px) {
  section.contact-section .form-container form {
    padding: 48px 32px;
  }
}

section.contact-section .form-container form input,
section.contact-section .form-container form textarea {
  font-size: 1.8rem;
  border: none;
  border-bottom: 1px solid black;
}

section.contact-section .form-container form input::-webkit-input-placeholder,
section.contact-section .form-container form textarea::-webkit-input-placeholder {
  color: black;
}

section.contact-section .form-container form input:-ms-input-placeholder,
section.contact-section .form-container form textarea:-ms-input-placeholder {
  color: black;
}

section.contact-section .form-container form input::-ms-input-placeholder,
section.contact-section .form-container form textarea::-ms-input-placeholder {
  color: black;
}

section.contact-section .form-container form input::placeholder,
section.contact-section .form-container form textarea::placeholder {
  color: black;
}

section.contact-section .form-container form button {
  text-transform: uppercase;
  background-color: transparent;
  height: 50px;
  border: 1px solid black;
  border-radius: 500px;
  font-size: 1.8rem;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

section.contact-section .form-container form button:hover {
  color: white;
  background-color: #222;
}

section.contact-section img {
  position: absolute;
  left: -10%;
  bottom: -5%;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.1;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
}

@media (max-width: 1550px) {
  section.contact-section .container {
    gap: 1.6rem;
  }
  section.contact-section .container .text-container {
    width: 550px;
  }
}

@media (max-width: 1025px) {
  section.contact-section .container .text-container {
    -ms-grid-column-align: end;
        justify-self: end;
    width: 90%;
  }
  section.contact-section .container .form-container {
    width: 90%;
  }
}

@media (max-width: 785px) {
  section.contact-section img {
    bottom: unset;
    top: 0;
    left: -35%;
  }
  section.contact-section .container .text-container {
    width: 70%;
    -ms-grid-column-align: center;
        justify-self: center;
  }
}

section.footer-section {
  min-height: 50vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

section.footer-section iframe {
  width: 100vw;
}

section.footer-section footer {
  background-color: #f2f2f2;
  width: 100%;
  padding: 3.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.6rem;
}

section.footer-section footer h4 {
  margin: 0 1.6rem 0 3.2rem;
  font-family: serif;
  font-size: 2.4rem;
  font-weight: 500;
}

section.footer-section footer img {
  height: 64px;
}

@media (max-width: 785px) {
  section.footer-section footer {
    padding: 16px 32px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 5fr;
        grid-template-columns: 1fr 5fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
  }
  section.footer-section footer h4 {
    margin: 0;
  }
  section.footer-section footer span {
    grid-column: 1/-1;
  }
}
